export const getCurrentApiUrl = (onlyDomain = false): string => {
  const url = window.location.origin;

  let baseURL = '';
  switch (true) {
    case url.includes('localhost'):
      baseURL = 'https://dev.elvo.io';
      break;
    default:
      baseURL = url;
  }
  return baseURL + (onlyDomain ? '' : '/api');
};

export const getHubApiUrl = (): string => {
  return 'https://hub.elvo.io/hub';
};
