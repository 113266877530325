import * as yup from 'yup';
import { isValidBIC, isValidIBAN } from 'ibantools';
import { escapeRegExp } from './regex';
import { translate } from './i18n/i18n';
import { isValidPhone } from './phoneNumber';

export const schemaDefault = yup.object().shape({
  key: yup.string().required(translate('input.validation.required')),
});

export const schemaNumber = yup.object().shape({
  key: yup.number().typeError(translate('input.validation.number')).required(translate('input.validation.required')),
});

export const schemaNumberPositive = yup.object().shape({
  key: yup
    .number()
    .min(0, translate('input.validation.positiveNumber'))
    .typeError(translate('input.validation.positiveNumber'))
    .required(translate('input.validation.required')),
});

export const schemaNumberPositiveNotRequired = yup.object().shape({
  key: yup
    .string()
    .test('min', translate('input.validation.positiveNumber'), (val) => {
      if (!val || !val.length) {
        return true;
      }
      return Number(val) >= 0;
    })
    .typeError(translate('input.validation.positiveNumber')),
});

export const schemaNoWhiteSpaces = yup.object().shape({
  key: yup
    .string()
    .test('key', translate('input.validation.spacesNotAllowed'), (val) => !/\s/g.test(val ?? ''))
    .required(translate('input.validation.required')),
});

export const jsonSchema = yup.object().shape({
  key: yup
    .string()
    .test('len', translate('input.validation.json'), (val) => {
      try {
        if (!val) {
          return false;
        }
        JSON.parse(val);
      } catch (e) {
        return false;
      }
      return true;
    })
    .required(translate('input.validation.required')),
});

export const getSchemaStationDelete = (value: string) => {
  const regex = new RegExp(`^${escapeRegExp(value)}$`);

  return yup.object().shape({
    key: yup
      .string()
      .trim()
      .matches(regex, translate('input.validation.stationID'))
      .required(translate('input.validation.required')),
  });
};

export const getSchemaMinMaxNumber = (min = Number.MIN_VALUE, max = Number.MAX_VALUE) =>
  yup.object().shape({
    key: yup
      .number()
      .integer(translate('input.validation.number'))
      .min(min, translate('input.validation.minValue', { min }))
      .max(max, translate('input.validation.maxValue', { max }))
      .required(translate('input.validation.required')),
  });

export const schemaEmail = yup.object().shape({
  key: yup
    .string()
    .test('email', translate('input.validation.email'), (val) =>
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(val ?? '')
    )
    .required(translate('input.validation.required')),
});

export const schemaPhoneNumber = yup.object().shape({
  key: yup
    .string()
    .test('phone', translate('input.validation.phone'), (val) => {
      if (!val || val.length < 7 || val.length > 13) {
        return false;
      }
      return isValidPhone(val);
    })
    .required(translate('input.validation.required')),
});

export const schemaUrl = yup.object().shape({
  key: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      translate('input.validation.url')
    )
    .required(translate('input.validation.required')),
});

export const schemaPasswordConfirm = (newPassword: string) =>
  yup.object().shape({
    key: yup
      .string()
      .trim()
      .test('confirmNewPassword', translate('input.validation.passwordConfirm'), (val) => val === newPassword)
      .required(translate('input.validation.required')),
  });

export const schemaPassword = yup.object().shape({
  key: yup
    .string()
    .test('password', translate('input.validation.password'), (val) => val !== undefined && val.length >= 6)
    .required(translate('input.validation.required')),
});

export const schemaIBAN = yup.object().shape({
  key: yup
    .string()
    .test('iban', translate('input.validation.IBAN'), (val) => {
      const cleared = val?.trim()?.replace(/\s/g, '') ?? '';
      return isValidIBAN(cleared);
    })
    .required(translate('input.validation.required')),
});

export const schemaSwiftBic = yup.object().shape({
  key: yup
    .string()
    .test('iban', translate('input.validation.swift'), (val) => {
      if (!val) {
        return false;
      }
      return isValidBIC(val);
    })
    .required(translate('input.validation.required')),
});

export const validate = (schema: yup.Schema, value?: string | number | object) => {
  try {
    schema.validateSync({ key: value });
    return {
      isValid: schema.isValidSync({ key: value }),
      error: '',
    };
  } catch (e: any) {
    return {
      isValid: schema.isValidSync({ key: value }),
      error: e?.errors ?? '',
    };
  }
};
