import axios, { InternalAxiosRequestConfig } from 'axios';
import { getHubApiUrl, loadFromLocalStorage } from 'utils';

const abortControllers = {} as { [key: string]: AbortController };

const options = {
  baseURL: getHubApiUrl(),
};

export const axiosHubRequest = axios.create(options);

axiosHubRequest.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401 && !window.location.pathname.includes('/auth/')) {
      window.location.replace('/logout');
    }
    return Promise.reject(error);
  }
);

axiosHubRequest.interceptors.request.use((request) => {
  // ABORT REQUEST IF ALREADY IN PENDING
  const extraProps = {} as Partial<InternalAxiosRequestConfig>;

  if (request?.method?.toLowerCase() === 'get' && request.url?.length) {
    if (abortControllers[request.url]) {
      abortControllers[request.url].abort();
    }
    abortControllers[request.url] = new AbortController();
    extraProps.signal = abortControllers[request.url].signal;
  }

  const user = loadFromLocalStorage('user');
  if (user?.token) {
    request.headers.Authorization = `Bearer ${user.token}`;
  }

  return {
    ...request,
    ...extraProps,
  };
});
